import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Col from 'react-bootstrap/Col';
import Placeholder from 'react-bootstrap/Placeholder';

const HiddenPrivateThumb = ({ handleKeyboardVisible }) => {
  const ref = useRef();
  
  useEffect(() => {
    ref.current.addEventListener("mousemove", (e) => {
      const rect = e.target.getBoundingClientRect();
      const imageCenterX = rect.left + rect.width / 2;
      const imageCenterY = rect.top + rect.height / 2;
      const clientX = e.clientX;
      const clientY = e.clientY;
      const xCalc = (clientX - imageCenterX) * 0.0000005;
      const yCalc = (clientY - imageCenterY) * 0.0000005;
      
      e.currentTarget.style.setProperty('transform', `matrix3d(
        1,0,0,${xCalc},
        0,1,0,${yCalc},
        0,0,1,0,
        0,0,0,1
      )`);
    });

    ref.current.addEventListener('mouseout', (e) => {
      e.currentTarget.style.setProperty('transform', `matrix3d(
        1,0,0,0,
        0,1,0,0,
        0,0,1,0,
        0,0,0,1
      )`);
    });
  }, []);

  return (
    <Col 
      className='private-projects__thumb' 
      md={6} 
      sm={12} 
      onClick={handleKeyboardVisible}
    >
      <div className='private-projects__thumb-image' ref={ref}>
        <StaticImage 
          alt='Blurry'
          src='../../images/hidden.jpg'
          placeholder='blurred'
        />
      </div>
      <Placeholder className='projects__name' as='div' animation='wave'>
        <Placeholder xs={5} bg='light' />
      </Placeholder>
      <Placeholder className='projects__company' as='div' animation='wave'>
        <Placeholder xs={4} bg='dark' />
      </Placeholder>
    </Col>
  )
}

export default HiddenPrivateThumb