import React, { useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Col from 'react-bootstrap/Col';

const PrivateProjectThumb = ({ uid, name, image, company }) => {
  const ref = useRef();
  
  useEffect(() => {
    ref.current.addEventListener("mousemove", (e) => {
      const rect = e.target.getBoundingClientRect();
      const imageCenterX = rect.left + rect.width / 2;
      const imageCenterY = rect.top + rect.height / 2;
      const clientX = e.clientX;
      const clientY = e.clientY;
      const xCalc = (clientX - imageCenterX) * 0.0000005;
      const yCalc = (clientY - imageCenterY) * 0.0000005;
      
      e.currentTarget.style.setProperty('transform', `matrix3d(
        1,0,0,${xCalc},
        0,1,0,${yCalc},
        0,0,1,0,
        0,0,0,1
      )`);
    });

    ref.current.addEventListener('mouseout', (e) => {
      e.currentTarget.style.setProperty('transform', `matrix3d(
        1,0,0,0,
        0,1,0,0,
        0,0,1,0,
        0,0,0,1
      )`);
    });
  }, []);

  return (
    <Col key={uid} className='private-projects__thumb' md={6} sm={12}>
      <Link to={`/projects/${uid}`}>
        <div className='private-projects__thumb-image' ref={ref}>
          <GatsbyImage 
            alt={name} 
            image={getImage(image)} 
          />
        </div>
        <div className='projects__name'>{name}</div>
        <div className='projects__company'>{company}</div>
      </Link>
    </Col>
  )
}

export default PrivateProjectThumb