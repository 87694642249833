import React, { Component } from 'react';
import { motion } from 'framer-motion';
import Row from 'react-bootstrap/Row';

import PublicProjectThumb from './projects/PublicProjectThumb';
import PrivateProjectThumb from './projects/PrivateProjectThumb';
import HiddenPrivateThumb from './projects/PrivateHiddenThumb';
import Keypad from './projects/Keypad';

import '../../node_modules/react-simple-keyboard/build/css/index.css';
import '../styles/components/_keypad.scss';

class ProjectsGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      privateVisible: false,
      setShow: false,
      input: '',
      inputPattern: /^-?\d+\.?\d*$/
    }

    this.handleKeyboardVisible = this.handleKeyboardVisible.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleKeyboardClose = this.handleKeyboardClose.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        this.handleSubmit();
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        this.handleSubmit();
      }
    });
  }

  onChange = input => {
    this.setState({ input });
  }

  onChangeInput = e => {
    let input = e.target.value;
    this.setState({ input });
    this.keyboard.setInput(input);
  }

  onKeyPress = button => {
    if (button === '{clear}') this.clearScreen();
  };

  clearScreen = () => {
    let input = { ...this.state.input };
    input = '';

    this.setState({ input }, () => {
      this.keyboard.clearInput();
    });
  };

  handleKeyboardVisible = () => {
    this.setState(prevState => ({
      setShow: !prevState.setShow
    }));
  }

  handleKeyboardClose = () => {
    this.setState(prevState => ({
      setShow: !prevState.setShow,
      input: ''
    }));
    this.keyboard.clearInput();
    this.keyboard.destroy();
  }

  handleKeyPress = (e) => {
    if (e.keyCode !== 8 && e.keyCode !== 37 && e.keyCode !== 39 && e.code !== 'Digit0' && e.code !== 'Digit1' && e.code !== 'Digit2' && e.code !== 'Digit3' && e.code !== 'Digit4' && e.code !== 'Digit5' && e.code !== 'Digit6' && e.code !== 'Digit7' && e.code !== 'Digit8' && e.code !== 'Digit9') {
      e.preventDefault();
      return false;
    }
  }

  handleSubmit = () => {
    if (this.state.input === '121778') {
      this.setState({
        privateVisible: true,
        setShow: false,
        input: ''
      })
      this.keyboard.clearInput();
      this.keyboard.destroy();
    } else {
      this.clearScreen();
      this.setState({ input: 'DENIED' }, () => {
        setTimeout(() => { this.setState({ input: '' }) }, 1000)
      })
    }
  }

  render() {
    return (
      <motion.section
        className='container' 
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: [0.61, 1, 0.88, 1] }}
      >
        <Row className='projects__grid'>
          {this.props.publicProjects.edges.map((document) => (
            <PublicProjectThumb 
              key={document.node.uid} 
              company={document.node.data.company.text} 
              image={document.node.data.thumbnail} 
              name={document.node.data.project_name.text} 
              uid={document.node.uid} 
            />
          ))}
        </Row>
        <Row className='private-projects__grid'>
          <div className='private__header'>
            <h4>PRIVATE PROJECTS</h4>
            <span className='line'></span>
          </div>
          {this.state.privateVisible === false ?
            <>
              {this.props.privateProjects.edges.map((document, i) => (
                <HiddenPrivateThumb 
                  key={i} 
                  handleKeyboardVisible={this.handleKeyboardVisible} 
                />
              ))}
              <Keypad 
                handleKeyPress={this.handleKeyPress} 
                handleSubmit={this.handleSubmit} 
                input={this.state.input} 
                keyboardRef={r => (this.keyboard = r)} 
                onChange={this.onChange} 
                onChangeInput={this.onChangeInput} 
                onHide={this.handleKeyboardClose} 
                setShow={this.state.setShow} 
              />
            </>
            :
            this.props.privateProjects.edges.map((document) => (
              <PrivateProjectThumb 
                key={document.node.uid} 
                company={document.node.data.company.text} 
                image={document.node.data.thumbnail} 
                name={document.node.data.project_name.text} 
                uid={document.node.uid} 
              />
            ))
          }
        </Row>
      </motion.section>
    )
  }
}

export default ProjectsGrid

