import React from 'react';
import Keyboard from 'react-simple-keyboard';
import Modal from 'react-bootstrap/Modal';

const Keypad = ({ handleKeyboardClose, handleKeyPress, handleSubmit, input, keyboardRef, onChange, onChangeInput, setShow }) => (
  <Modal show={setShow} centered onHide={handleKeyboardClose}>
    <h1 className='keypad__title'>Enter Passcode</h1>
    <input
      id='astInput' 
      className='keypad__pin-input' 
      maxLength={6} 
      onChange={onChangeInput} 
      onKeyDown={handleKeyPress} 
      placeholder='• • • • • •' 
      type='text' 
      value={input} 
    />
    <Keyboard 
      keyboardRef={keyboardRef} 
      onChange={onChange} 
      buttonTheme={[
        {
          class: 'hg-transparent-button',
          buttons: '{numpad1} {numpad2} {numpad3} {numpad4} {numpad5} {numpad6} {numpad7} {numpad8} {numpad9} {numpad0}'
        }
      ]}
      layout={{
        'default': [
          '{numpad7} {numpad8} {numpad9}',
          '{numpad4} {numpad5} {numpad6}',
          '{numpad1} {numpad2} {numpad3}',
          '{numpad0}'
        ]
      }} 
      display={{
        '{numpad0}': '0',
        '{numpad1}': '1',
        '{numpad2}': '2',
        '{numpad3}': '3',
        '{numpad4}': '4',
        '{numpad5}': '5',
        '{numpad6}': '6',
        '{numpad7}': '7',
        '{numpad8}': '8',
        '{numpad9}': '9'
      }} 
      physicalKeyboardHighlight={true}
      physicalKeyboardHighlightPress={true}
      physicalKeyboardHighlightTextColor={'#000 !important'}
      physicalKeyboardHighlightBgColor={'rgba(255, 255, 255, .5) !important'}
      maxLength={6}
    />
    <button className='keypad__submit' onClick={handleSubmit}>SUBMIT</button>
  </Modal>
)

export default Keypad