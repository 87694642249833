import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { motion, useIsPresent } from 'framer-motion';

import Seo from '../components/Seo';
import Layout from '../components/Layout';
import ProjectsGrid from '../components/ProjectsGrid';

import '../styles/pages/_projects.scss';

const Projects = () => {
  const isPresent = useIsPresent();
  const data = useStaticQuery(graphql`
    query allProjectsQuery {
      allPrismicProject(sort: {fields: data___order}) {
        edges {
          node {
            uid
            data {
              project_name {
                text
              }
              thumbnail {
                gatsbyImageData(placeholder: BLURRED)
              }
              description {
                text
              }
              company {
                text
              }
              role {
                html
              }
              external_link {
                url
              }
            }
          }
        }
      }
      allPrismicPrivateProjects(sort: {fields: data___order}) {
        edges {
          node {
            uid
            data {
              project_name {
                text
              }
              thumbnail {
                gatsbyImageData(outputPixelDensities: 2, placeholder: BLURRED)
              }
              description {
                text
              }
              company {
                text
              }
              role {
                html
              }
              external_link {
                url
              }
            }
          }
        }
      }
    }`
  )

  return (
    <>
    <Seo title='Projects' />
    <Layout pageId='projects'>
      <ProjectsGrid publicProjects={data.allPrismicProject} privateProjects={data.allPrismicPrivateProjects} />
      <motion.div
        initial={{ scaleY: 1 }}
        animate={{ scaleY: 0, transition: { duration: 0.75, ease: [0.16, 1, 0.3, 1] } }}
        exit={{ scaleY: 1, transition: { duration: 0.75, ease: [0.7, 0, 0.84, 0] } }}
        style={{ originY: isPresent ? 0 : 1 }}
        className='screen-page-transition'
      />
    </Layout>
    </>
  )
}

export default Projects